// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/entry.client.tsx"
);
import.meta.hot.lastModified = "1727806535423.0706";
}
// REMIX HMR END

import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";

if (ENV.SENTRY_DSN && process.env.NODE_ENV === 'production') {
	import('~/services/monitoring.client.tsx').then(({ init }) => init())
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
